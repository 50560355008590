<template>
  <b-row>
    <b-col md="4">
      <!-- alteração do valor é feito no método onSelectStore -->
      <e-store-combo
        id="sale_prepare_form-store_id"
        :value="formData.storeId"
        :required="true"
        :clearable="false"
        :disabled="isReadOnly || isEdit"
        @input="onSelectStore"
      />
    </b-col>
    <b-col md="2">
      <FormulateInput
        id="sale_prepare_form-price_table"
        v-model="formData.priceTableId"
        type="label"
        :label="$t('Tabela de preço')"
        :options="getComboPriceTables"
        class="required"
        validation="required"
        :instruction="$t('Tabela de preço do cliente no momento que o pedido foi criado.')"
      />
    </b-col>

    <b-col md="2">
      <FormulateInput
        v-if="!isReadOnly"
        id="sale_prepare_form-is_consigned"
        v-model="formData.isConsigned"
        type="switch"
        :label="$t('Consignado?')"
        :disabled="isReadOnly"
        @input="onChangeConsign"
      />

      <div
        v-else
        style="max-width: 100px"
      >
        <p class="h6">
          {{ $t('Consignado?') }}
        </p>
        <e-status-badge
          :status="!!formData.isConsigned"
          type="yesNo"
        />
      </div>
    </b-col>

    <b-col md="2">
      <e-slot-authenticated
        v-model="formData.discountAuth"
        :delegable-permission="discountPermission"
        :store-id="formData.store ? formData.store.id : 0"
        :is-valid-store="!!formData.store"
        :is-read-only="isReadOnly"
      >
        <template #content="slotProps">
          <FormulateInput
            id="sale_prepare_form-discount_type"
            v-model="formData.discountType"
            type="radio"
            :options="discountTypesLabel"
            :label="$t('Desconto em')"
            :element-class="['d-flex', 'mt-1']"
            :disabled="slotProps.isReadOnly"
          />
        </template>
      </e-slot-authenticated>
    </b-col>

    <b-col md="2">
      <e-slot-authenticated
        v-model="formData.discountAuth"
        :delegable-permission="discountPermission"
        :store-id="formData.store ? formData.store.id : 0"
        :is-valid-store="!!formData.store"
        :is-read-only="isReadOnly"
      >
        <template #content="slotProps">
          <FormulateInput
            v-if="slotProps.isReadOnly"
            id="order-discount"
            v-model="formData.discount"
            name="discount_percentage"
            type="label"
            :label="`${$t('Desconto')} (${getDiscountSymbol})`"
            :filter="isDiscountPercent ? 'percentage' : 'currency'"
            :instruction="$t('Desconto do pedido')"
          />
          <FormulateInput
            v-else
            id="sale_prepare_form-discount"
            v-model="formData.discount"
            name="discount_percentage"
            :type="isDiscountPercent ? 'text-percetage' : 'text-number'"
            :label="`${$t('Desconto')} (${getDiscountSymbol})`"
            :currency="getDiscountSymbol"
            :currency-symbol-position="isDiscountPercent ? 'suffix' : 'prefix'"
            :precision="2"
            :instruction="$t('Desconto do pedido')"
          />
        </template>
      </e-slot-authenticated>
    </b-col>

    <b-col md="2">
      <FormulateInput
        v-if="!isReadOnly"
        id="sale_prepare_form-is_delivery"
        v-model="formData.isDelivery"
        type="switch"
        :label="$t('Entrega?')"
        :disabled="isReadOnly"
        @input="onChangeDelivery"
      />

      <div
        v-else
        style="max-width: 100px"
      >
        <p class="h6">
          {{ $t('Entrega?') }}
        </p>
        <e-status-badge
          :status="!!formData.isDelivery"
          type="yesNo"
        />
      </div>
    </b-col>

    <template v-if="showDeliveryData">
      <b-col md="3">
        <FormulateInput
          id="sale_prepare_form-delivery_date"
          v-model="formData.deliveryDate.date"
          type="datepicker"
          :placeholder="$t('dd/mm/aaaa')"
          :label="$t('Data de entrega')"
          class="required"
          validation="required"
          :disabled="isReadOnly"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="sale_prepare_form-delivery_time"
          v-model="formData.deliveryDate.time"
          type="timepicker"
          :placeholder="$t('hh:mm')"
          :label="$t('Hora de entrega')"
          class="required"
          validation="required"
          :disabled="isReadOnly"
        />
      </b-col>
      <b-col>
        <FormulateInput
          id="sale_prepare_form-delivery_observation"
          v-model="formData.deliveryObservation"
          type="textarea"
          :label="$t('Observação da entrega')"
          :disabled="isReadOnly"
        />
      </b-col>
    </template>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { discountTypes } from '@/mixins'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import ESlotAuthenticated from '@/views/components/ESlotAuthenticated.vue'
import delegablePermissions from '@/utils/delegable-permissions'

export default {
  components: {
    BRow,
    BCol,
    EStoreCombo,
    EStatusBadge,
    ESlotAuthenticated,
  },

  mixins: [discountTypes],

  /** emits:
   * update-prices
   * select-store
   * change-delivery
   */

  props: {
    /**
      {
        storeId,
        priceTableId,
        isConsigned,
        discountType,
        discount,
        isDelivery,
        deliveryDate.date,
        deliveryDate.time,
        deliveryObservation,
      }
     */
    formData: {
      type: Object,
      required: true,
    },

    isQuotation: {
      type: Boolean,
      default: false,
    },

    isEdit: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('common/priceTables', ['getComboPriceTables']),
    showDeliveryData() {
      return !this.isQuotation && (this.formData.isDelivery || this.formData.isConsigned)
    },

    isDiscountPercent() {
      return this.formData.discountType === this.discountTypesEnum.PERCENTAGE
    },
    getDiscountSymbol() {
      return this.isDiscountPercent ? this.$t('%') : this.$t('R$')
    },

    discountPermission() {
      return delegablePermissions.ERP_ORDER_DISCOUNT
    },
  },

  methods: {
    formatDiscount(value, type) {
      return type === this.discountTypesEnum.PERCENTAGE
        ? this.$options.filters.percentage(value)
        : this.$options.filters.currency(value)
    },

    onSelectPriceTable(val) {
      this.$emit('select-price-table', val)
    },

    onSelectStore(val) {
      this.$emit('select-store', val)
    },
    onChangeDelivery(val) {
      this.$emit('change-delivery', val)
    },
    onChangeConsign(val) {
      this.$emit('change-consign', val)
    },
  },
}
</script>

<style lang="scss" scoped></style>
