<template>
  <section>
    <FormulateForm
      ref="quotationForm"
      name="quotationForm"
    >
      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="`${$t('Dados da cotação')}${quotation.id ? ` #${quotation.id}` : ''}`"
      >
        <sale-order-form
          :form-data="quotation"
          is-quotation
          :is-edit="isEdit"
          :is-read-only="isReadOnly"
          @select-price-table="updatePrices"
          @select-store="onSelectStore"
          @change-delivery="onChangeDelivery"
          @change-consign="onChangeConsign"
        />
      </b-card-actions>

      <b-row>
        <b-col :md="showDeliveryCard ? 6 : 12">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Dados do Cliente')"
          >
            <b-row>
              <b-col md="8">
                <e-person-search
                  id="quotation_maintain-customer"
                  v-model="quotation.customerId"
                  :label="$t('Cliente')"
                  required
                  is-customer
                  :is-read-only="isReadOnly"
                  :default-options="customerDefaultOptions"
                  @input="onSelectCustomer"
                />
              </b-col>
              <template v-if="quotation.customer">
                <b-col md="4">
                  <FormulateInput
                    id="quotation_maintain-document"
                    v-model="quotation.customer.document"
                    type="label"
                    :label="$t('CPF/CNPJ')"
                    filter="cpfCnpj"
                  />
                </b-col>
                <b-col md="6">
                  <FormulateInput
                    id="quotation_maintain-email"
                    v-model="quotation.customer.email"
                    type="label"
                    :label="$t('Email')"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    id="quotation_maintain-phone"
                    v-model="getCustomerTelephone.phone"
                    type="label"
                    :label="$t('Telefone')"
                    filter="phone"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    id="quotation_maintain-cellphone"
                    v-model="getCustomerTelephone.cellphone"
                    type="label"
                    :label="$t('Celular')"
                    filter="phone"
                  />
                </b-col>

                <b-col
                  v-if="isDelivery"
                  md="12"
                >
                  <FormulateInput
                    id="quotation_maintain-address_options"
                    v-model="quotation.addressSelected"
                    type="vue-select"
                    :label="$t('Endereço para entrega')"
                    :options="getCustomerAddressOptions"
                    :clearable="false"
                    class="required"
                    :placeholder="$t('Selecione')"
                    validation="required"
                    :disabled="isReadOnly"
                    @input="changeAddress"
                  />
                </b-col>
              </template>
            </b-row>
          </b-card-actions>
        </b-col>

        <b-col
          v-if="showDeliveryCard"
          md="6"
        >
          <e-address
            v-if="!isReadOnly"
            v-model="quotation.deliveryAddress"
            :show-loading="loading"
          />
          <b-card-actions
            v-else
            no-actions
            :title="$t('Endereço')"
          >
            <e-address
              v-model="quotation.deliveryAddress"
              :show-loading="loading"
              :read-only="true"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row v-if="isDelivery">
        <b-col>
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Dados de entrega')"
          >
            <b-row>
              <b-col md="3">
                <FormulateInput
                  id="order_maintain-delivery_fee_type"
                  v-model="deliveryData.deliveryFeeType"
                  type="radio"
                  :options="deliveryFeeLabel"
                  :label="$t('Tipo da taxa de entrega')"
                  :element-class="['d-flex', 'mt-1']"
                  :disabled="isReadOnly"
                  @input="onChangeDeliveryFeeType"
                />
              </b-col>

              <template v-if="isDeliveryPerKilometer">
                <b-col md="3">
                  <FormulateInput
                    id="order-fee_per_kilometer_value"
                    v-model="deliveryData.shippingFeePerKilometer"
                    type="label"
                    :label="$t('Valor da taxa por KM da loja')"
                    filter="currency"
                    :instruction="$t('Valor da taxa por quilômetro configurada para a loja selecionada.')
                    "
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    id="order-distance_value"
                    v-model="deliveryData.distanceValue"
                    type="number"
                    :label="$t('Distância em KM')"
                    validation="required"
                    class="required"
                    :instruction="$t('Distância em quilômetros da loja até o endereço de entrega.')"
                    :disabled="isReadOnly"
                    @blur="onUpdateDelivery"
                  />
                </b-col>
              </template>

              <template v-else>
                <b-col md="3">
                  <FormulateInput
                    id="order-single_fee_value"
                    v-model="deliveryData.shippingFee"
                    type="label"
                    :label="$t('Valor da taxa da loja')"
                    filter="currency"
                    :instruction="$t('Valor da taxa configurada para a loja selecionada.')"
                  />
                </b-col>
              </template>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>

      <b-card-actions
        v-if="quotation.customer && quotation.storeId"
        :show-loading="loading || loadingProducts"
        action-collapse
        :title="$t('Produtos')"
      >
        <b-row>
          <b-col
            v-if="!isReadOnly"
            md="12"
            class="mb-1 d-flex justify-content-end"
          >
            <e-button
              :text="$t('Adicionar produto')"
              variant="primary"
              @click="() => onShowProductSidebar()"
            />
          </b-col>

          <b-col md="12">
            <sale-product-table
              :items="getProducts"
              :is-read-only="isReadOnly"
              @show-sidebar-update="onShowProductSidebar"
              @remove-item="onRemoveProduct"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end">
            <div>
              <p
                v-if="isDelivery"
                class="h4 text-right"
              >
                {{ $t('Taxa de conveniência:') }}
                <span class="h3">
                  {{ deliveryData.deliveryTax | currency }}
                </span>
              </p>

              <p
                v-if="getQuotationTotalDiscount > 0"
                class="h4 text-right"
              >
                {{ $t('Desconto Total:') }}
                <span class="h3">
                  {{ getQuotationTotalDiscount | currency }}
                </span>
              </p>

              <p class="h3 text-right">
                {{ $t('Cotação total:') }}
                <span class="h2">
                  {{ getQuotationTotalProducts | currency }}
                </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card-actions>
    </FormulateForm>

    <div class="pb-1" />

    <sale-product-sidebar
      v-if="quotation.storeId"
      ref="saleProductSidebar"
      :store-id="quotation.storeId"
      :price-table-id="quotation.priceTableId"
      @update="onUpdateProduct"
      @add="onAddProduct"
    />

    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      :busy="loading"
      @save="saveQuotation"
      @cancel-quotation="onCancel"
      @back="back"
      @send-to-order="onCreateOrder"
      @print-quotation="onPrintQuotation"
    />
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { deliveryDomain, discountTypes, loadingOverlay } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import EAddress from '@/views/components/EAddress.vue'
import Fab from '@/views/components/FAB.vue'
import isElectron from 'is-electron'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import SaleProductSidebar from '../components/SaleProductSidebar.vue'
import SaleProductTable from '../components/SaleProductTable.vue'
import SaleOrderForm from '../components/forms/SaleOrderForm.vue'

export default {
  name: 'QuotationMaintain',
  components: {
    BRow,
    BCol,
    BCardActions,
    EButton,
    SaleProductSidebar,
    EAddress,
    Fab,
    SaleProductTable,
    SaleOrderForm,
    EPersonSearch,
  },

  mixins: [discountTypes, deliveryDomain, loadingOverlay],

  data() {
    return {
      loading: false,
      loadingProducts: false,
      isToUpdatePrice: true,
    }
  },

  computed: {
    ...mapState('pages/sale/quotation/quotationMaintain', ['quotation', 'deliveryData']),
    ...mapGetters('pages/sale/quotation/quotationMaintain', [
      'getProducts',
      'getCustomerAddressOptions',
      'getCustomerTelephone',
      'getQuotationTotalProducts',
      'getQuotationTotalDiscount',
    ]),

    isEdit() {
      return !!this.$route.params.id
    },

    isReadOnly() {
      return !!this.$route.name.includes('read-only')
    },

    isDelivery() {
      return this.quotation.isDelivery
    },

    isDeliveryPerKilometer() {
      return this.deliveryData.deliveryFeeType === this.deliveryFeeEnum.FEE_PER_KILOMETER
    },

    showDeliveryCard() {
      return (
        this.quotation.customer !== null &&
        this.quotation.isDelivery &&
        this.quotation.addressSelected?.id === -1
      )
    },

    customerDefaultOptions() {
      const customer = this.quotation?.customer
      if (customer && Object.keys(customer).length > 0) return [customer]
      return []
    },

    actions() {
      return [
        {
          name: 'back',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
          show: true,
        },
        {
          name: 'save',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Salvar'),
          show: !this.isReadOnly,
        },
        {
          name: 'send-to-order',
          icon: 'open_in_new',
          color: 'green',
          tooltip: this.$t('Criar novo pedido'),
          show: this.isReadOnly,
        },
        {
          name: 'cancel-quotation',
          icon: 'cancel',
          color: 'red',
          tooltip: this.$t('Cancelar Cotação'),
          show: this.isReadOnly,
        },
        {
          name: 'print-quotation',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Imprimir Cotação'),
          show: this.isReadOnly && isElectron(),
        },
      ].filter(action => action.show)
    },
  },

  async mounted() {
    this.fetchPriceTables()

    if (this.isEdit) {
      try {
        this.isToUpdatePrice = false
        this.loading = true
        await this.stFetchQuotationById(this.$route.params.id)
      } catch (err) {
        this.showError({ message: err.message })
      } finally {
        this.loading = false
        this.isToUpdatePrice = true
      }
    }
  },

  methods: {
    ...mapActions('common/priceTables', ['fetchPriceTables']),
    ...mapActions('pages/sale/quotation', ['printQuotation']),
    ...mapActions('pages/sale/quotation/quotationMaintain', {
      stFetchQuotationById: 'fetchQuotationById',
      stFetchStoreById: 'fetchStoreById',
      stFetchCustomerById: 'fetchCustomerById',
      stSaveQuotation: 'saveQuotation',
      stAddItem: 'addProduct',
      stUpdateItem: 'updateProduct',
      stRemoveItem: 'removeProduct',
      stSetQuotationStoreId: 'setQuotationStoreId',
      stSetDeliveryAddress: 'setDeliveryAddress',
      stCleanState: 'cleanState',
      stCleanDeliveryAddress: 'cleanDeliveryAddress',
      stCleanInputsAuth: 'cleanInputsAuth',
      stCleanQuotationCustomer: 'cleanCustomer',
      stUpdateProductPrices: 'updateProductPrices',
      stUpdateDeliveryTax: 'updateDeliveryTax',
    }),
    ...mapActions('pages/sale/order/orderMaintain', {
      stSetIsQuotationReceived: 'setIsQuotationReceived',
    }),

    onShowProductSidebar(item) {
      this.$refs.saleProductSidebar.show(item)
    },

    async onAddProduct(formData) {
      await this.stAddItem({ formData })
    },
    onUpdateProduct(formData) {
      this.stUpdateItem({ formData })
    },
    async onRemoveProduct(item) {
      const confirmed = await this.confirm()
      if (confirmed) {
        this.stRemoveItem({ id: item.id || item.localId })
      }
    },

    async onPrintQuotation() {
      try {
        this.busy = true
        this.showLoadingOverlay(this.$t('Imprimindo cupom'))
        await this.printQuotation(this.quotation)

        this.showSuccess({ message: '' })
      } catch (err) {
        /* eslint-disable no-console */
        console.error('print:', err)
        this.busy = false
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async saveQuotation() {
      this.$refs.quotationForm.showErrors()
      if (this.$refs.quotationForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.loading = true
        await this.stSaveQuotation()
        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.stCleanState()
        this.$router.push({ name: 'quotation-list' })
      } catch (err) {
        this.showError({ error: err })
      } finally {
        this.loading = false
      }
    },

    async updatePrices() {
      if (!this.isToUpdatePrice) return

      try {
        this.loadingProducts = true
        await this.stUpdateProductPrices()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingProducts = false
      }
    },

    async onCancel() {
      const { id } = this.quotation
      try {
        const confirm = await this.confirm()
        if (confirm) {
          this.fetching = true
          await this.$http.delete(`/api/sales/quotations/${id}`)
          this.stCleanState()
          this.$router.push({ name: 'quotation-list' })
          this.showSuccess({
            message: this.$t('Cotação cancelada com sucesso.'),
          })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async back() {
      this.stCleanState()
      this.$router.push({ name: 'quotation-list' })
    },

    async onCreateOrder() {
      this.stSetIsQuotationReceived(true)
      this.$router.push({ name: 'order-add' })
    },

    onUpdateDelivery() {
      if (this.isReadOnly || this.loading) return
      this.stUpdateDeliveryTax()
    },

    async onSelectCustomer(val) {
      if (val) {
        try {
          await this.stFetchCustomerById({ customerId: val })
          await this.updatePrices()
        } catch (error) {
          this.showError({ error })
        }
      } else {
        this.stCleanQuotationCustomer()
      }
    },
    async onSelectStore(storeId) {
      if (!storeId || this.loading) return

      const oldStoreId = this.quotation.storeId
      if (oldStoreId && storeId !== oldStoreId) {
        const confirmed = await this.confirm({
          text: this.$t(
            'Ao alterar a loja, os valores de delivery e produtos podem ser atualizados e os campos que precisam de autorização terão valor e autorização resetados.'
          ),
        })

        if (!confirmed) {
          // necessário para forçar o combo a alterar o valor.
          await this.stSetQuotationStoreId({ storeId: null })
          await this.stSetQuotationStoreId({ storeId: oldStoreId })
          return
        }
        await this.stCleanInputsAuth()
      }

      await this.stSetQuotationStoreId({ storeId })
      await this.stFetchStoreById({ storeId })
      await this.onUpdateDelivery()
      this.updatePrices()
    },

    onChangeDeliveryFeeType(val) {
      if (!val) return
      this.onUpdateDelivery()
    },
    onChangeDelivery(val) {
      if (val) this.onUpdateDelivery()
    },
    onChangeConsign(val) {
      if (val) this.onUpdateDelivery()
    },

    changeAddress(addressData) {
      // TODO buscar taxa de convêniencia
      if (addressData) this.stSetDeliveryAddress({ addressData })
      else this.stCleanDeliveryAddress()
    },
  },
}
</script>
