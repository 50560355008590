var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c("e-store-combo", {
            attrs: {
              id: "sale_prepare_form-store_id",
              value: _vm.formData.storeId,
              required: true,
              clearable: false,
              disabled: _vm.isReadOnly || _vm.isEdit,
            },
            on: { input: _vm.onSelectStore },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { md: "2" } },
        [
          _c("FormulateInput", {
            staticClass: "required",
            attrs: {
              id: "sale_prepare_form-price_table",
              type: "label",
              label: _vm.$t("Tabela de preço"),
              options: _vm.getComboPriceTables,
              validation: "required",
              instruction: _vm.$t(
                "Tabela de preço do cliente no momento que o pedido foi criado."
              ),
            },
            model: {
              value: _vm.formData.priceTableId,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "priceTableId", $$v)
              },
              expression: "formData.priceTableId",
            },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { md: "2" } },
        [
          !_vm.isReadOnly
            ? _c("FormulateInput", {
                attrs: {
                  id: "sale_prepare_form-is_consigned",
                  type: "switch",
                  label: _vm.$t("Consignado?"),
                  disabled: _vm.isReadOnly,
                },
                on: { input: _vm.onChangeConsign },
                model: {
                  value: _vm.formData.isConsigned,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "isConsigned", $$v)
                  },
                  expression: "formData.isConsigned",
                },
              })
            : _c(
                "div",
                { staticStyle: { "max-width": "100px" } },
                [
                  _c("p", { staticClass: "h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Consignado?")) + " "),
                  ]),
                  _c("e-status-badge", {
                    attrs: {
                      status: !!_vm.formData.isConsigned,
                      type: "yesNo",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { md: "2" } },
        [
          _c("e-slot-authenticated", {
            attrs: {
              "delegable-permission": _vm.discountPermission,
              "store-id": _vm.formData.store ? _vm.formData.store.id : 0,
              "is-valid-store": !!_vm.formData.store,
              "is-read-only": _vm.isReadOnly,
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function (slotProps) {
                  return [
                    _c("FormulateInput", {
                      attrs: {
                        id: "sale_prepare_form-discount_type",
                        type: "radio",
                        options: _vm.discountTypesLabel,
                        label: _vm.$t("Desconto em"),
                        "element-class": ["d-flex", "mt-1"],
                        disabled: slotProps.isReadOnly,
                      },
                      model: {
                        value: _vm.formData.discountType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "discountType", $$v)
                        },
                        expression: "formData.discountType",
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.formData.discountAuth,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "discountAuth", $$v)
              },
              expression: "formData.discountAuth",
            },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { md: "2" } },
        [
          _c("e-slot-authenticated", {
            attrs: {
              "delegable-permission": _vm.discountPermission,
              "store-id": _vm.formData.store ? _vm.formData.store.id : 0,
              "is-valid-store": !!_vm.formData.store,
              "is-read-only": _vm.isReadOnly,
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function (slotProps) {
                  return [
                    slotProps.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "order-discount",
                            name: "discount_percentage",
                            type: "label",
                            label:
                              _vm.$t("Desconto") +
                              " (" +
                              _vm.getDiscountSymbol +
                              ")",
                            filter: _vm.isDiscountPercent
                              ? "percentage"
                              : "currency",
                            instruction: _vm.$t("Desconto do pedido"),
                          },
                          model: {
                            value: _vm.formData.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "discount", $$v)
                            },
                            expression: "formData.discount",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "sale_prepare_form-discount",
                            name: "discount_percentage",
                            type: _vm.isDiscountPercent
                              ? "text-percetage"
                              : "text-number",
                            label:
                              _vm.$t("Desconto") +
                              " (" +
                              _vm.getDiscountSymbol +
                              ")",
                            currency: _vm.getDiscountSymbol,
                            "currency-symbol-position": _vm.isDiscountPercent
                              ? "suffix"
                              : "prefix",
                            precision: 2,
                            instruction: _vm.$t("Desconto do pedido"),
                          },
                          model: {
                            value: _vm.formData.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "discount", $$v)
                            },
                            expression: "formData.discount",
                          },
                        }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.formData.discountAuth,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "discountAuth", $$v)
              },
              expression: "formData.discountAuth",
            },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { md: "2" } },
        [
          !_vm.isReadOnly
            ? _c("FormulateInput", {
                attrs: {
                  id: "sale_prepare_form-is_delivery",
                  type: "switch",
                  label: _vm.$t("Entrega?"),
                  disabled: _vm.isReadOnly,
                },
                on: { input: _vm.onChangeDelivery },
                model: {
                  value: _vm.formData.isDelivery,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "isDelivery", $$v)
                  },
                  expression: "formData.isDelivery",
                },
              })
            : _c(
                "div",
                { staticStyle: { "max-width": "100px" } },
                [
                  _c("p", { staticClass: "h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Entrega?")) + " "),
                  ]),
                  _c("e-status-badge", {
                    attrs: { status: !!_vm.formData.isDelivery, type: "yesNo" },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _vm.showDeliveryData
        ? [
            _c(
              "b-col",
              { attrs: { md: "3" } },
              [
                _c("FormulateInput", {
                  staticClass: "required",
                  attrs: {
                    id: "sale_prepare_form-delivery_date",
                    type: "datepicker",
                    placeholder: _vm.$t("dd/mm/aaaa"),
                    label: _vm.$t("Data de entrega"),
                    validation: "required",
                    disabled: _vm.isReadOnly,
                  },
                  model: {
                    value: _vm.formData.deliveryDate.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.deliveryDate, "date", $$v)
                    },
                    expression: "formData.deliveryDate.date",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { md: "2" } },
              [
                _c("FormulateInput", {
                  staticClass: "required",
                  attrs: {
                    id: "sale_prepare_form-delivery_time",
                    type: "timepicker",
                    placeholder: _vm.$t("hh:mm"),
                    label: _vm.$t("Hora de entrega"),
                    validation: "required",
                    disabled: _vm.isReadOnly,
                  },
                  model: {
                    value: _vm.formData.deliveryDate.time,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.deliveryDate, "time", $$v)
                    },
                    expression: "formData.deliveryDate.time",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-col",
              [
                _c("FormulateInput", {
                  attrs: {
                    id: "sale_prepare_form-delivery_observation",
                    type: "textarea",
                    label: _vm.$t("Observação da entrega"),
                    disabled: _vm.isReadOnly,
                  },
                  model: {
                    value: _vm.formData.deliveryObservation,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "deliveryObservation", $$v)
                    },
                    expression: "formData.deliveryObservation",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }