var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "quotationForm", attrs: { name: "quotationForm" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title:
                  "" +
                  _vm.$t("Dados da cotação") +
                  (_vm.quotation.id ? " #" + _vm.quotation.id : ""),
              },
            },
            [
              _c("sale-order-form", {
                attrs: {
                  "form-data": _vm.quotation,
                  "is-quotation": "",
                  "is-edit": _vm.isEdit,
                  "is-read-only": _vm.isReadOnly,
                },
                on: {
                  "select-price-table": _vm.updatePrices,
                  "select-store": _vm.onSelectStore,
                  "change-delivery": _vm.onChangeDelivery,
                  "change-consign": _vm.onChangeConsign,
                },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: _vm.showDeliveryCard ? 6 : 12 } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Dados do Cliente"),
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c("e-person-search", {
                                attrs: {
                                  id: "quotation_maintain-customer",
                                  label: _vm.$t("Cliente"),
                                  required: "",
                                  "is-customer": "",
                                  "is-read-only": _vm.isReadOnly,
                                  "default-options": _vm.customerDefaultOptions,
                                },
                                on: { input: _vm.onSelectCustomer },
                                model: {
                                  value: _vm.quotation.customerId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.quotation, "customerId", $$v)
                                  },
                                  expression: "quotation.customerId",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.quotation.customer
                            ? [
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "quotation_maintain-document",
                                        type: "label",
                                        label: _vm.$t("CPF/CNPJ"),
                                        filter: "cpfCnpj",
                                      },
                                      model: {
                                        value: _vm.quotation.customer.document,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.quotation.customer,
                                            "document",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "quotation.customer.document",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "quotation_maintain-email",
                                        type: "label",
                                        label: _vm.$t("Email"),
                                      },
                                      model: {
                                        value: _vm.quotation.customer.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.quotation.customer,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "quotation.customer.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "quotation_maintain-phone",
                                        type: "label",
                                        label: _vm.$t("Telefone"),
                                        filter: "phone",
                                      },
                                      model: {
                                        value: _vm.getCustomerTelephone.phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.getCustomerTelephone,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "getCustomerTelephone.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "quotation_maintain-cellphone",
                                        type: "label",
                                        label: _vm.$t("Celular"),
                                        filter: "phone",
                                      },
                                      model: {
                                        value:
                                          _vm.getCustomerTelephone.cellphone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.getCustomerTelephone,
                                            "cellphone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "getCustomerTelephone.cellphone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.isDelivery
                                  ? _c(
                                      "b-col",
                                      { attrs: { md: "12" } },
                                      [
                                        _c("FormulateInput", {
                                          staticClass: "required",
                                          attrs: {
                                            id: "quotation_maintain-address_options",
                                            type: "vue-select",
                                            label: _vm.$t(
                                              "Endereço para entrega"
                                            ),
                                            options:
                                              _vm.getCustomerAddressOptions,
                                            clearable: false,
                                            placeholder: _vm.$t("Selecione"),
                                            validation: "required",
                                            disabled: _vm.isReadOnly,
                                          },
                                          on: { input: _vm.changeAddress },
                                          model: {
                                            value:
                                              _vm.quotation.addressSelected,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.quotation,
                                                "addressSelected",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "quotation.addressSelected",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showDeliveryCard
                ? _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      !_vm.isReadOnly
                        ? _c("e-address", {
                            attrs: { "show-loading": _vm.loading },
                            model: {
                              value: _vm.quotation.deliveryAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.quotation, "deliveryAddress", $$v)
                              },
                              expression: "quotation.deliveryAddress",
                            },
                          })
                        : _c(
                            "b-card-actions",
                            {
                              attrs: {
                                "no-actions": "",
                                title: _vm.$t("Endereço"),
                              },
                            },
                            [
                              _c("e-address", {
                                attrs: {
                                  "show-loading": _vm.loading,
                                  "read-only": true,
                                },
                                model: {
                                  value: _vm.quotation.deliveryAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.quotation,
                                      "deliveryAddress",
                                      $$v
                                    )
                                  },
                                  expression: "quotation.deliveryAddress",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isDelivery
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "show-loading": _vm.loading,
                            "action-collapse": "",
                            title: _vm.$t("Dados de entrega"),
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "order_maintain-delivery_fee_type",
                                      type: "radio",
                                      options: _vm.deliveryFeeLabel,
                                      label: _vm.$t("Tipo da taxa de entrega"),
                                      "element-class": ["d-flex", "mt-1"],
                                      disabled: _vm.isReadOnly,
                                    },
                                    on: { input: _vm.onChangeDeliveryFeeType },
                                    model: {
                                      value: _vm.deliveryData.deliveryFeeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveryData,
                                          "deliveryFeeType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveryData.deliveryFeeType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isDeliveryPerKilometer
                                ? [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("FormulateInput", {
                                          attrs: {
                                            id: "order-fee_per_kilometer_value",
                                            type: "label",
                                            label: _vm.$t(
                                              "Valor da taxa por KM da loja"
                                            ),
                                            filter: "currency",
                                            instruction: _vm.$t(
                                              "Valor da taxa por quilômetro configurada para a loja selecionada."
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.deliveryData
                                                .shippingFeePerKilometer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveryData,
                                                "shippingFeePerKilometer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveryData.shippingFeePerKilometer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("FormulateInput", {
                                          staticClass: "required",
                                          attrs: {
                                            id: "order-distance_value",
                                            type: "number",
                                            label: _vm.$t("Distância em KM"),
                                            validation: "required",
                                            instruction: _vm.$t(
                                              "Distância em quilômetros da loja até o endereço de entrega."
                                            ),
                                            disabled: _vm.isReadOnly,
                                          },
                                          on: { blur: _vm.onUpdateDelivery },
                                          model: {
                                            value:
                                              _vm.deliveryData.distanceValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveryData,
                                                "distanceValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveryData.distanceValue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("FormulateInput", {
                                          attrs: {
                                            id: "order-single_fee_value",
                                            type: "label",
                                            label: _vm.$t(
                                              "Valor da taxa da loja"
                                            ),
                                            filter: "currency",
                                            instruction: _vm.$t(
                                              "Valor da taxa configurada para a loja selecionada."
                                            ),
                                          },
                                          model: {
                                            value: _vm.deliveryData.shippingFee,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveryData,
                                                "shippingFee",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveryData.shippingFee",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.quotation.customer && _vm.quotation.storeId
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "show-loading": _vm.loading || _vm.loadingProducts,
                    "action-collapse": "",
                    title: _vm.$t("Produtos"),
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      !_vm.isReadOnly
                        ? _c(
                            "b-col",
                            {
                              staticClass: "mb-1 d-flex justify-content-end",
                              attrs: { md: "12" },
                            },
                            [
                              _c("e-button", {
                                attrs: {
                                  text: _vm.$t("Adicionar produto"),
                                  variant: "primary",
                                },
                                on: {
                                  click: function () {
                                    return _vm.onShowProductSidebar()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("sale-product-table", {
                            attrs: {
                              items: _vm.getProducts,
                              "is-read-only": _vm.isReadOnly,
                            },
                            on: {
                              "show-sidebar-update": _vm.onShowProductSidebar,
                              "remove-item": _vm.onRemoveProduct,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c("div", [
                            _vm.isDelivery
                              ? _c("p", { staticClass: "h4 text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Taxa de conveniência:")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "h3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.deliveryData.deliveryTax
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.getQuotationTotalDiscount > 0
                              ? _c("p", { staticClass: "h4 text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Desconto Total:")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "h3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getQuotationTotalDiscount
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("p", { staticClass: "h3 text-right" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Cotação total:")) + " "
                              ),
                              _c("span", { staticClass: "h2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.getQuotationTotalProducts
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "pb-1" }),
      _vm.quotation.storeId
        ? _c("sale-product-sidebar", {
            ref: "saleProductSidebar",
            attrs: {
              "store-id": _vm.quotation.storeId,
              "price-table-id": _vm.quotation.priceTableId,
            },
            on: { update: _vm.onUpdateProduct, add: _vm.onAddProduct },
          })
        : _vm._e(),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          "fixed-tooltip": true,
          busy: _vm.loading,
        },
        on: {
          save: _vm.saveQuotation,
          "cancel-quotation": _vm.onCancel,
          back: _vm.back,
          "send-to-order": _vm.onCreateOrder,
          "print-quotation": _vm.onPrintQuotation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }